"use strict";
(function () {
  class ActivityLogComponent {
    constructor(
      $scope,
      User,
      $state,
      Site,
      usSpinnerService,
      toastr,
      Util,
      Auth,
      ActivityLog
    ) {
      this.Util = Util;
      this.toastr = toastr;
      this.usSpinnerService = usSpinnerService;
      this.isDisabled = false;
      this.errors = {};
      this.submitted = false;
      this.Auth = Auth;
      this.User = User;
      this.isSuccess = false;
      this.$state = $state;
      this.ActivityLog = ActivityLog;
      this.totalItems = 1;
      this.currentPage = 1;
      this.pageSize = 10;
      this.users = User.query();
      this.Site = Site;
      this.datePicker = {
        date: { startDate: null, endDate: null },
        options: {
          ranges: {
            Today: [moment(), moment()],
            Yesterday: [
              moment().subtract(1, "days"),
              moment().subtract(1, "days"),
            ],
            "Last 7 Days": [moment().subtract(6, "days"), moment()],
            "Last 14 Days": [moment().subtract(13, "days"), moment()],
            "Last 30 Days": [moment().subtract(29, "days"), moment()],
          },
        },
      };

      Auth.isLoggedInAsync((loggedIn) => {
        if (loggedIn) {
          this.init();
          this.currentUser = Auth.getCurrentUser();
        } else {
          $state.go("login");
        }
      });
    }

    setPage(pageNo) {
      this.currentPage = pageNo;
    }

    pageChanged() {
      console.log("pageChanged", this.currentPage);
      this.filterActivityLogData(this.currentPage);
      // $log.log('Page changed to: ' + this.currentPage);
    }

    loadData() {
      this.Site.get({ populateBlade: true })
        .$promise.then((response) => {
          if (response.data && response.data.length) {
            this.sites = response.data;
            this.isSiteLoaded = true;
          }
        })
        .catch((err) => {
          console.log(err);
          this.isSiteLoaded = true;
        });

      this.ActivityLog.get({
        currentPage: this.currentPage,
        pageSize: this.pageSize,
      })
        .$promise.then((response) => {
          if (response.success && response.data && response.data.length) {
            this.logs = response.data;
            this.totalLogs = response.count;
            // this.loading = false;
            this.stopSpin("spinner-2");
            console.log("Activity Log:", response);
          }
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
          this.stopSpin("spinner-2");
        });
    }

    init() {
      this.loadData();
    }

    filterActivityLogData(pageNo) {
      let filterQueryData = {
        startDate: this.datePicker.date.startDate,
        endDate: this.datePicker.date.endDate,
        type: this.type,
        action: this.action,
        source: this.source,
        currentPage: pageNo,
        pageSize: this.pageSize,
      };

      if (this.site) {
        filterQueryData.site = this.site._id;
      }
      if (this.turbine) {
        filterQueryData.turbine = this.turbine._id;
      }
      if (this.blade) {
        filterQueryData.blade = this.blade._id;
      }

      if (this.user) {
        filterQueryData.user = this.user;
      }

      if (this.repairId) {
        filterQueryData.repairId = this.repairId;
      }
      console.log("filterQueryData:", filterQueryData);
      this.ActivityLog.filterActivityLog(filterQueryData)
        .$promise.then((response) => {
          this.logs = response.data;
          this.totalLogs = response.count;
          this.currentPage = pageNo;
        })
        .catch((err) => {
          console.log("Error filtering activityLog", err);
        });
    }

    startSpin(spinner) {
      this.usSpinnerService.spin(spinner);
    }

    stopSpin(spinner) {
      this.usSpinnerService.stop(spinner);
    }

    getSiteTurbines() {
      this.site.turbines.forEach((turbine) => {
        let displayTurbine = "";
        if (turbine.name) {
          displayTurbine += turbine.name;
        }
        if (turbine.model) {
          displayTurbine += " " + turbine.model;
        }
        if (turbine.serial) {
          displayTurbine += " (" + turbine.serial + ")";
        }
        turbine.displayTurbine = displayTurbine.trim();
      });

      this.site.turbines = _.sortBy(this.site.turbines, (turbine) => {
        return [turbine.displayTurbine.toLowerCase()];
      });
    }

    getTurbineBlades() {
      this.turbine.blades.forEach((blade) => {
        let displayBlade = "";
        if (blade.position) {
          displayBlade += blade.position;
        }
        if (blade.model) {
          displayBlade += " (" + blade.model + ")";
        }
        blade.displayBlade = displayBlade.trim();
      });

      this.turbine.blades = _.sortBy(this.turbine.blades, (blade) => {
        return [blade.displayBlade.toLowerCase()];
      });
    }
  }

  angular.module("windmanagerApp").component("activityLog", {
    templateUrl: "app/activity-log/activity-log.html",
    controller: ActivityLogComponent,
    controllerAs: "activityLog",
  });
})();
